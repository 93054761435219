import * as React from "react"

// import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <iframe
      style={{ margin: "0", padding: "0", width: "100%", height: "97vh" }}
      src="https://cdn.forms-content.sg-form.com/7752296e-10d5-11ec-a4f8-eac9f875d2d2"
      frameBorder="0"
    ></iframe>
  </>
)

export default IndexPage
